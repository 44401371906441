import React, { useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Menu } from '../data/menu'
import isExternalLink from '../utils/isExternalLink'
import LinkExtended from './LinkExtended'

const MenuMobileWrapper = styled.ul`
  list-style: none;
  gap: 3px;
  display: flex;
  flex-direction: column;
`

const item = css`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #ffffff;
  opacity: 0.5;
  text-decoration: none;
  padding: 9px 13px;
  cursor: pointer;
`

const MenuMobileLabel = styled.span`
  ${item}
`

const MenuMobileLink = styled(LinkExtended)`
  ${item}
`

const MenuMobileExternalLink = styled.a`
  ${item}
`

const MenuMobileItemExpanded = styled.li`
  background: rgba(243, 243, 243, 0.19);
  border-radius: 11px;
  display: inline-flex;
  flex-direction: column;
  padding: 9px 13px;
  margin: 2px 0 16px 0;

  & > a {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 28px;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }

  & > ul {
    display: flex;
    list-style: none;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }
`

const MenuMobileSubLink = styled(LinkExtended)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  text-decoration: none;
`

interface MenuMobileProps {
  menu: Menu
}

const MenuMobile: React.FC<MenuMobileProps> = ({ menu }) => {
  const [selected, setSelected] = useState<number>()
  const handleSelected = useCallback(
    (index: number) => () => setSelected(index),
    []
  )

  return (
    <MenuMobileWrapper>
      {menu
        .filter((x) => !x.hideOnMobile)
        .map((item, index) =>
          !item.items ? (
            <li key={index}>
              {isExternalLink(item.url) ? (
                <MenuMobileExternalLink href={item.url}>
                  {item.label}
                </MenuMobileExternalLink>
              ) : (
                <MenuMobileLink to={item.url} noprefetch>
                  {item.label}
                </MenuMobileLink>
              )}
            </li>
          ) : selected !== index ? (
            <li key={index} onClick={handleSelected(index)}>
              <MenuMobileLabel>{item.label}</MenuMobileLabel>
            </li>
          ) : (
            <MenuMobileItemExpanded key={index}>
              <LinkExtended to={item.url} noprefetch>
                {item.label}
              </LinkExtended>
              <ul>
                {item.items.map((subItem, subIndex) => (
                  <li key={subIndex}>
                    <MenuMobileSubLink to={subItem.url} noprefetch>
                      {subItem.label}
                    </MenuMobileSubLink>
                  </li>
                ))}
              </ul>
            </MenuMobileItemExpanded>
          )
        )}
    </MenuMobileWrapper>
  )
}

export default MenuMobile
