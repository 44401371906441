import { SavequoteModelQueryVariables } from '../_generated/codegen/graphqlTypes'
import { repairBrandProductPageBuilder } from './ReparationPage.context'

export type RepairOtherBrandPageContext = SavequoteModelQueryVariables & {
  shopsCount: number
  productTypeLowerCase: string
  productTypeLabel?: string
  brandNameLowerCase: string
  productTypeUrlCode: string
}

export const repairOtherBrandPageBuilder = (
  productType: string,
  brandName: string
) => {
  return `${repairBrandProductPageBuilder(productType)}${(
    brandName ?? ''
  ).toLowerCase()}/`
}
