import { useMemo } from 'react'
import { FooterItem } from '../data/footer'
import { repairBrandPageBuilder } from '../templates/RepairBrandPage.context'
import notEmpty from '../utils/notEmpty'
import useSaveMenuQuery from './useSaveMenuQuery'
import footer, { Footer } from '../data/footer'
import { MENU_MAX_PRIMARY_BRANDS, reparationsLinks } from '../data/menu.context'

const useFooterMenu = (): Footer => {
  const saveMenu = useSaveMenuQuery()

  return useMemo(() => {
    const brands = saveMenu?.brands?.filter(notEmpty) ?? []
    const primaryBrands = brands.slice(0, MENU_MAX_PRIMARY_BRANDS)

    const repairItem: FooterItem = {
      label: 'Réparation',
      url: reparationsLinks.all,
      items: [
        { label: 'Réparation smartphone', url: reparationsLinks.all },
        ...primaryBrands.map((brand) => ({
          label: brand.name ?? '',
          url: repairBrandPageBuilder(brand.name ?? ''),
        })),
      ],
    }

    return [repairItem, ...footer]
  }, [saveMenu])
}

export default useFooterMenu
