import React from 'react'
import Layout from '../src/components/Layout'
import { GatsbyBrowser, GatsbySSR } from 'gatsby'
import { LayoutProps } from '../src/components/Layout.context'
import { QueryClient, QueryClientProvider } from 'react-query'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import LayoutExternal from '../src/components/LayoutExternal'

export type PageContext = {
  layoutProps?: LayoutProps
  matchPath?: string
}

type WrapPageElementParameters =
  | Parameters<NonNullable<GatsbyBrowser['wrapPageElement']>>
  | Parameters<NonNullable<GatsbySSR['wrapPageElement']>>

type WrapPageElementParametersArgs = WrapPageElementParameters[0] & {
  props: WrapPageElementParameters[0]['props'] & {
    pageContext: WrapPageElementParameters[0]['props']['pageContext'] &
      PageContext
  }
  element: WrapPageElementParameters[0]['element'] & {
    type: WrapPageElementParameters[0]['element']['type'] & {
      layoutProps?: LayoutProps
    }
  }
}

const queryClient = new QueryClient()

type IWrapPageElement = (
  args: WrapPageElementParametersArgs,
  options: WrapPageElementParameters[1]
) => React.ReactElement

export const WrapPageElement: IWrapPageElement = ({ element, props }) => {
  if (props.location.pathname.includes('external')) {
    return (
      <QueryClientProvider client={queryClient}>
        <LayoutExternal
          {...props}
          layoutProps={merge(
            cloneDeep(props?.pageContext?.layoutProps),
            element?.type?.layoutProps
          )}
        >
          {element}
        </LayoutExternal>
      </QueryClientProvider>
    )
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <Layout
          {...props}
          layoutProps={merge(
            cloneDeep(props?.pageContext?.layoutProps),
            element?.type?.layoutProps
          )}
        >
          {element}
        </Layout>
      </QueryClientProvider>
    )
  }
}
