import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Link, GatsbyLinkProps, navigate } from 'gatsby'
import styled from 'styled-components'

const InvisibleLink = styled(Link)`
  display: none;
`

const PreFetch: React.FC<{ page: string }> = ({ page }) => {
  const ref = useRef<HTMLAnchorElement>(null)
  useEffect(() => {
    ref?.current?.dispatchEvent(
      new MouseEvent('mouseover', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    )
  }, [])

  return (
    <InvisibleLink
      to={page}
      // @ts-ignore
      ref={ref}
    />
  )
}

interface LinkExtendedProps extends GatsbyLinkProps<any> {
  noprefetch?: boolean
  ref?: string
}

const LinkExtended: React.FC<LinkExtendedProps> = memo(
  ({ children, to, noprefetch, onClick, onMouseOver, ...rest }) => {
    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()

        onClick && onClick(e)
        navigate(to)
      },
      [to, onClick]
    )

    const [prefetch, setPrefetch] = useState(false)

    const handleMouseOver = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        onMouseOver && onMouseOver(e)
        setPrefetch(true)
      },
      [to, onMouseOver]
    )

    return noprefetch ? (
      <>
        <a
          href={to}
          onClick={handleClick}
          onMouseOver={handleMouseOver}
          {...rest}
        >
          {children}
        </a>
        {prefetch && <PreFetch page={to} />}
      </>
    ) : (
      <Link to={to} onClick={onClick} onMouseOver={onMouseOver} {...rest}>
        {children}
      </Link>
    )
  }
)

export default LinkExtended
