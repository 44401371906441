type Size = {
  width: string
  height: string
}

export type Sizes = {
  desktop: Size
  mobile: Size
}

export type Highlight = {
  title: string
  icon: {
    path: string
    sizes: Sizes
  }
  description: string
}

export type Highlights = Highlight[]

const highlights: Highlights = [
  {
    title: 'Leader de la réparation',
    icon: {
      path: `${process.env.GATSBY_SITE_URL}images/leader-reparation_white.png`,
      sizes: {
        desktop: { width: '44px', height: '44px' },
        mobile: { width: '44px', height: '44px' },
      },
    },
    description: `
      Save est le leader de la réparation avec près de 150 magasins en France
    `,
  },
  {
    title: 'Agréments constructeurs',
    icon: {
      path: `${process.env.GATSBY_SITE_URL}images/agrements_white.png`,
      sizes: {
        desktop: { width: '44px', height: '44px' },
        mobile: { width: '44px', height: '44px' },
      },
    },
    description: `
      Réparer toutes les marques, 
      c'est bien, le faire avec l'aval 
      des marques, c'est Save.
    `,
  },
  {
    title: 'Économie Circulaire',
    icon: {
      path: `${process.env.GATSBY_SITE_URL}images/economie-circulaire_white.png`,
      sizes: {
        desktop: { width: '44px', height: '34px' },
        mobile: { width: '44px', height: '44px' },
      },
    },
    description: `
      Recycler et donner une seconde vie, c'est profiter de la technologie,
      mais de manière responsable ! 
    `,
  },
  {
    title: 'Réparation Garantie 1 an',
    icon: {
      path: `${process.env.GATSBY_SITE_URL}images/garantie_white.png`,
      sizes: {
        desktop: { width: '44px', height: '44px' },
        mobile: { width: '44px', height: '44px' },
      },
    },
    description: `
      Nous savons ce que nous faisons,  nos réparations sont garanties 
      un an, pièces et main d'œuvre.
    `,
  },
]

export default highlights
