import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled, { css } from 'styled-components'

const Title = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
`

const Description = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
`

const Image = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & > * {
    width: 35px;
    height: 35px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 43px;
      height: 43px;
    }
  }
`

type BannerType =
  | 'validation'
  | 'promo'
  | 'erreur'
  | 'actualite'
  | 'livraison'
  | 'mail'

interface ContainerProps {
  type: BannerType
}

const Container = styled.div<ContainerProps>`
  opacity: 0.8;
  padding: 12px 20px 17px 20px;
  display: grid;
  row-gap: 6px;
  grid-template-areas:
    'logo        title       _'
    'description description description';
  grid-template-columns: 85px 1fr 85px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-areas:
      'logo title       _'
      'logo description _';
    grid-template-columns: 85px auto 85px;
    justify-content: center;
  }

  & > :nth-child(1) {
    grid-area: logo;
  }

  & > :nth-child(2) {
    grid-area: title;
  }

  & > :nth-child(3) {
    grid-area: description;
  }

  background-color: ${({ theme }) => theme.colors.tertiary};

  ${({ type }) => {
    switch (type) {
      case 'validation':
        return css`
          background-color: ${({ theme }) => theme.colors.tertiary};
        `
      case 'promo':
        return css`
          background-color: ${({ theme }) => theme.colors.primary};
        `
      case 'erreur':
        return css`
          background-color: ${({ theme }) => theme.colors.danger};
        `
      case 'actualite':
      case 'livraison':
      case 'mail':
        return css`
          background-color: ${({ theme }) => theme.colors.secondary};
        `
    }
  }}
`

interface BannerProps {
  type: BannerType
  title: React.ReactNode
  description?: React.ReactNode
}

const Banner: React.FC<BannerProps> = ({ type, title, description }) => (
  <Container type={type}>
    <Image>
      {type === 'validation' && (
        <StaticImage
          src="../images/SaveLogoWhite.svg"
          alt=""
          placeholder="blurred"
          quality={100}
        />
      )}

      {type === 'promo' && (
        <StaticImage
          src="../images/PromoWhite.svg"
          alt=""
          placeholder="blurred"
          quality={100}
        />
      )}

      {type === 'erreur' && (
        <StaticImage
          src="../images/ErrorWhite.svg"
          alt=""
          placeholder="blurred"
          quality={100}
        />
      )}

      {type === 'actualite' && (
        <StaticImage
          src="../images/NewsWhite.svg"
          alt=""
          placeholder="blurred"
          quality={100}
        />
      )}

      {type === 'livraison' && (
        <StaticImage
          src="../images/DeliveryWhite.svg"
          alt=""
          placeholder="blurred"
          quality={100}
        />
      )}

      {type === 'mail' && (
        <StaticImage
          src="../images/MailWhite.svg"
          alt=""
          placeholder="blurred"
          quality={100}
        />
      )}
    </Image>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Container>
)

export default Banner
