import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import GlobalStyle from './GlobalStyle'
import styled, { ThemeProvider } from 'styled-components'
import theme from '../theme/theme'
import Footer from './Footer'
import Breadcrumb from './Breadcrumb'
import { Helmet } from 'react-helmet'
import Header from './Header'
import { PageProps } from 'gatsby'
import { LayoutProps } from './Layout.context'
import { BreadcrumbItems } from './Breadcrumb.context'
import RepairStepsContainer from './RepairStepsContainer'
import RepairSteps from './RepairSteps'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import { WindowLocation } from '@reach/router'
import useSiteMetadata from '../hooks/useSiteMetadata'
import Banners from './Banners'
import { useIsomorphicEffect } from '../hooks/useIsomorphicEffect'

const Container = styled.div`
  max-width: ${({ theme }) => theme.container.maxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 44px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 81px;
  }

  & > main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }
`

const formatTitle = (title?: string) => (title ? `${title} | Save` : 'Save')

const useTitle = (title?: string, breadcrumb?: BreadcrumbItems) => {
  return useMemo(() => {
    if (title) {
      return title
    }

    if (breadcrumb && breadcrumb.length) {
      return breadcrumb[breadcrumb.length - 1].label
    }

    return undefined
  }, [title, breadcrumb])
}

const layoutContext = createContext<(layoutProps: LayoutProps) => void>(
  () => {}
)

const LayoutContextProvider = layoutContext.Provider

export const useLayoutContext = (layoutProps: LayoutProps) => {
  const setLayoutProps = useContext(layoutContext)

  useEffect(() => {
    setLayoutProps(layoutProps)
  }, [layoutProps])
}

const locationContext = createContext<WindowLocation<unknown> | undefined>(
  undefined
)

const LocationContextProvider = locationContext.Provider

export const useLocation = () => {
  return useContext(locationContext)
}

interface Props extends Omit<PageProps, 'children'> {
  layoutProps?: LayoutProps
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({
  children,
  layoutProps: layoutPropsStatic,
  location,
}) => {
  const siteMetadata = useSiteMetadata()
  const [layoutContextState, setLayoutContextState] = useState<
    LayoutProps | undefined
  >(undefined)
  const isomorphicEffect = useIsomorphicEffect()

  isomorphicEffect(() => {
    setLayoutContextState(undefined)
  }, [location.pathname])

  const layoutProps = useMemo(() => {
    return merge(cloneDeep(layoutPropsStatic), layoutContextState)
  }, [layoutContextState, layoutPropsStatic])

  const title = useTitle(layoutProps?.title, layoutProps?.breadcrumb)

  const canonical = useMemo(
    () => `${siteMetadata?.siteUrlWithoutSlash}${location.pathname}`,
    [location, siteMetadata]
  )

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'fr-FR',
        }}
      >
        <meta charSet="utf-8" />
        <title>{formatTitle(title)}</title>
        <link rel="canonical" href={canonical} />
        <link rel="alternate" href={canonical} hrefLang="fr-FR" />
        <link rel="icon" type="image/png" href="/favicon.ico" sizes="16x16" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <LocationContextProvider value={location}>
          <GlobalStyle />
          <header>
            <Header />
          </header>
          <Banners location={location} />
          <Container>
            <Breadcrumb items={layoutProps?.breadcrumb} />
            <main>
              <RepairStepsContainer {...layoutProps?.repairStepsContainerProps}>
                <RepairSteps {...layoutProps?.repairStepsProps} />
              </RepairStepsContainer>

              <LayoutContextProvider value={setLayoutContextState}>
                {children}
              </LayoutContextProvider>
            </main>
          </Container>
          <footer>
            <Footer />
          </footer>
        </LocationContextProvider>
      </ThemeProvider>
    </>
  )
}

export default Layout
