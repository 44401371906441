import React, { Suspense, useEffect } from 'react'
import styled from 'styled-components'
import SocialLinks from './SocialLinks'
import HelpIcon from '../images/Help.svg'
import HamburgerMenuIcon from '../images/HamburgerMenu.svg'
import MenuCloseIcon from '../images/MenuClose.svg'
import MenuMobile from './MenuMobile'
import MenuDesktop from './MenuDesktop'
import { useToggle } from 'react-use'
import SearchDesktop from './SearchDesktop'
import useLockBodyScroll from '../hooks/useLockBodyScroll'
import useMenu from '../hooks/useMenu'
import { useLocation } from './Layout'
import isServerSideRendering from '../utils/isServerSideRendering'
import LinkExtended from './LinkExtended'
// import AvatarIcon from '../images/Avatar.svg'
// import { authLink } from '../data/menu.context'

const SearchFormLazy = React.lazy(() => import('./SearchForm'))

if (!isServerSideRendering()) {
  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => import('./SearchForm'), 3 * 1000)
  })
}

const Nav = styled.nav`
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-right: 9px;
  min-height: 44px;
  width: 100%;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 33px;
    min-height: 81px;
  }
`

const Logo = styled(LinkExtended)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;

  max-width: 128px;
  max-height: 44px;

  & > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 234px;
    max-height: 81px;
  }
`

const NavDrawer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  overflow: auto;
  transform: translateZ(0);
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 36px 0 36px;

  // fix drawer bottom on safari only
  @supports (-webkit-touch-callout: none) {
    padding-bottom: 72px;
  }
`

const NavDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Divider = styled.hr`
  opacity: 0.15;
  display: inline-block;
  width: 100%;
  margin: 17px 0;
  background: black;
  min-height: 3px;
  border: none;
`

const NavDrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const NavDrawerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 12px;
  padding-bottom: 36px;
`

const ButtonClose = styled.span`
  position: absolute;
  top: 19px;
  left: 21px;
  cursor: pointer;
`

const ButtonOpen = styled.span`
  display: inline-flex;
  margin: 9px;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`

const Right = styled.div`
  display: flex;
`

// const Shadow = styled.span`
//   display: inline-flex;
//   box-shadow: ${({ theme }) => theme.shadows.xs};
//   border-radius: 50%;
// `

// const SignIn = styled(Link)`
//   text-decoration: none;
//   display: inline-flex;
//   align-items: center;
//   padding: 9px 0;
// `

// const SignInLabel = styled.span`
//   font-style: normal;
//   font-weight: bold;
//   font-size: 20px;
//   line-height: 24px;
//   color: #ffffff;
//   opacity: 0.5;
//   margin-left: 9px;
// `

const SearchFormWrapper = styled(SearchFormLazy)`
  width: 100%;
  z-index: 1;
`

const NavBar = () => {
  const menu = useMenu()
  const [isDrawerOpen, toggleMenu] = useToggle(false)
  useLockBodyScroll(isDrawerOpen)

  const location = useLocation()
  useEffect(() => {
    toggleMenu(false)
  }, [location, toggleMenu])

  return (
    <>
      <Nav>
        <ButtonOpen>
          <img
            src={HamburgerMenuIcon}
            alt="menu"
            width={30}
            height={26}
            onClick={toggleMenu}
          />
        </ButtonOpen>
        <MenuDesktop
          menu={menu.filter((x) => x.position === 'left')}
          responsive
        />

        <Logo to="/" noprefetch>
          <img
            src={`${process.env.GATSBY_SITE_URL}images/LogoSaveBlanc.svg`}
            placeholder="blurred"
            alt="Save"
          />
        </Logo>

        <Right>
          <SearchDesktop>
            <MenuDesktop
              menu={menu.filter((x) => x.position === 'right')}
              responsive
            />
          </SearchDesktop>

          <MenuDesktop
            menu={menu.filter((x) => x.position === 'borderRight')}
          />
        </Right>
      </Nav>

      {isDrawerOpen && (
        <NavDrawer>
          <ButtonClose>
            <img
              src={MenuCloseIcon}
              alt="close"
              width={41}
              height={41}
              onClick={toggleMenu}
            />
          </ButtonClose>
          <NavDrawerContainer>
            <NavDrawerHeader>
              <LinkExtended to="/" noprefetch>
                <img
                  src={`${process.env.GATSBY_SITE_URL}images/logo-Save-blanc-v-blanc.png`}
                  alt="Save"
                  width={169}
                  height={169}
                />
              </LinkExtended>
              <Suspense fallback={<></>}>
                <SearchFormWrapper variant="mobile" />
              </Suspense>
            </NavDrawerHeader>
            <Divider />
            <MenuMobile menu={menu} />
            <Divider />
            {/* <SignIn to={authLink}>
              <Shadow>
                <img src={AvatarIcon} alt="avatar" width={32} height={32} />
              </Shadow>
              <SignInLabel>Se connecter</SignInLabel>
            </SignIn> */}
          </NavDrawerContainer>
          <NavDrawerFooter>
            <SocialLinks />
            <a href="#">
              <img src={HelpIcon} alt="help" width={32} height={32} />
            </a>
          </NavDrawerFooter>
        </NavDrawer>
      )}
    </>
  )
}

export default NavBar
