import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import RepairProgress from './RepairProgress'
import { RepairStepsProps } from './RepairSteps.context'

const getStepHeader = (index: number): string => {
  switch (index) {
    case 0:
      return 'Mon appareil'

    case 1:
      return 'Mes sauveteurs'
    case 2:
    default:
      return 'Mon devis'
  }
}

const Container = styled.div`
  & > *[class*='RepairProgress'] {
    margin: 0 9%;
  }
`

const Steps = styled.div`
  display: flex;

  & > div {
    flex: 1;
  }
`

const Step = styled.div`
  text-align: center;
  padding-bottom: 10px;
`

const Header = styled.strong`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  display: inline-block;
  margin-bottom: 2px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 14px;
    line-height: 17px;
  }
`

const List = styled.ul``

const Item = styled.li``

const link = css`
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }
`

const StepSpan = styled.span`
  ${link}
`

const StepLink = styled(Link)`
  ${link}
`

const RepairSteps: React.FC<RepairStepsProps> = ({
  step1: step1Raw,
  step2: step2Raw,
  step3: step3Raw,
}) => {
  const step1 = useMemo(() => step1Raw ?? [], [step1Raw])
  const step2 = useMemo(() => step2Raw ?? [], [step2Raw])
  const step3 = useMemo(() => step3Raw ?? [], [step3Raw])

  const count = useMemo(
    () => step1.length + step2.length + step3.length,
    [step1, step2, step3]
  )

  const steps = useMemo(() => [step1, step2, step3], [step1, step2, step3])

  // TODO : fix progress bar 1 2 3 4
  return (
    <Container>
      <RepairProgress step={count} />

      <Steps>
        {steps.map((step, i) => (
          <Step key={i}>
            <Header>{getStepHeader(i)}</Header>
            <List>
              {step.map((item, j) => (
                <Item key={j}>
                  {typeof item.url === 'string' ? (
                    <StepLink to={item.url}>{item.label}</StepLink>
                  ) : (
                    <StepSpan onClick={item.url}>{item.label}</StepSpan>
                  )}
                </Item>
              ))}
            </List>
          </Step>
        ))}
      </Steps>
    </Container>
  )
}

export default RepairSteps
