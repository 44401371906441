import styled from 'styled-components'

const ResponsiveOrder = styled.div`
  display: flex;
  flex-direction: column;

  & > :last-child {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      order: -1;
    }
  }
`

export default ResponsiveOrder
