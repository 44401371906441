import React from 'react'
import styled from 'styled-components'
import { Highlights as IHighlights, Sizes } from '../data/highlights'
import theme from '../theme/theme'
import FadeUpWhenVisible from './FadeUpWhenVisible'

const Title = styled.strong`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: white;
`

const Description = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: white};
  display: none;
  max-width: 190px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`

const Hightlight = styled(FadeUpWhenVisible)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 7px;
  }
`

const HighlightsWrapper = styled.div`
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 101px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 176px;
  }
`

const Container = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  min-height: 60px;
  max-width: 500px;
  padding: 0 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 950px;
    min-height: 100px;
  }
`

interface IconProps {
  customSizes: Sizes
}

const Icon = styled.img<IconProps>`
  width: ${({ customSizes }) => customSizes.mobile.width};
  height: ${({ customSizes }) => customSizes.mobile.height};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: ${({ customSizes }) => customSizes.desktop.width};
    height: ${({ customSizes }) => customSizes.desktop.height};
  }
`

interface Props {
  items: IHighlights
  className?: string | undefined
}

const Highlights: React.FC<Props> = ({ items, className }) => (
  <HighlightsWrapper className={className}>
    <Container>
      {items.map((item, index) => (
        <Hightlight
          component="li"
          key={index}
          delay={theme.transitions.fadeUp.delay * index}
        >
          <Icon
            src={item.icon.path}
            alt={item.title}
            customSizes={item.icon.sizes}
          />
          <Title>{item.title}</Title>
          <Description>{item.description}</Description>
        </Hightlight>
      ))}
    </Container>
  </HighlightsWrapper>
)

export default Highlights
