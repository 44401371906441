import React from 'react'
import styled, { css } from 'styled-components'

const marginTop = css`
  margin-top: calc(-1 * (304 * 100vw / 1746));
`

const Container = styled.div`
  position: relative;

  // fix missing pixels on iOS
  &:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.secondary};
    ${marginTop}
  }
`

const Rect = styled.rect`
  fill: ${({ theme }) => theme.colors.secondary};
  clip-path: url(#clip);
  pointer-events: painted;
  height: 100%;
  width: 100%;
`

const Svg = styled.svg`
  pointer-events: none;
  width: 100%;
  height: auto;
  transform: translateY(-11px);
  ${marginTop}
`

const FooterContent = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  margin-top: -17px;
`

const FooterSvg = () => (
  <Svg
    width="1746"
    height="304"
    viewBox="0 0 1746 304"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMin"
  >
    <defs>
      <clipPath id="clip" clipRule="evenodd">
        <path d="M1745.49 0C1561.63 179.534 1239.46 298.563 872.744 298.563C506.029 298.563 183.857 179.534 0 3.57972e-05V303.5H1745.49V0Z" />
      </clipPath>
    </defs>
    <Rect y="0" x="0" width="1746" height="304" />
  </Svg>
)

interface Props {
  children?: React.ReactNode
}

const FooterBackground: React.FC<Props> = ({ children }) => (
  <Container>
    <FooterSvg />
    <FooterContent>{children}</FooterContent>
  </Container>
)

export default FooterBackground
