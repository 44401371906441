import termsLinks from './termsLinks'
import { magasinLink, menuServiceItems, servicesLink } from './menu'

type SubItem = {
  label: string
  url: string
}

export type FooterItem = {
  label: string
  url: string
  items: SubItem[]
}

export type Footer = FooterItem[]

const footer: Footer = [
  {
    label: 'Services',
    url: servicesLink,
    items: menuServiceItems,
  },
  {
    label: 'Nos magasins',
    url: magasinLink,
    items: [
      {
        label: 'Réparation Paris',
        url: `${magasinLink}smartphone/fr/paris`,
      },
      {
        label: 'Réparation Marseille',
        url: `${magasinLink}smartphone/fr/marseille`,
      },
      {
        label: 'Réparation Lyon',
        url: `${magasinLink}smartphone/fr/lyon`,
      },
    ],
  },
  {
    label: 'Liens Pratiques',
    url: termsLinks.cgv,
    items: [
      { label: 'CGV', url: termsLinks.cgv },
      { label: 'Mentions légales', url: termsLinks.mentions_legales },
      { label: 'Cookies', url: termsLinks.cookies },
    ],
  },
]

export default footer
