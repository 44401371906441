export type ProductType = {
  code: string
  label: string
  image: string
  urlCode: string
  arianeLabel: string
}

export const ProductTypeEnum = {
  TAB: 'TAB',
  LPT: 'LPT',
  IT: 'IT',
  CSL: 'CSL',
  WEA: 'WEA',
  OTH: 'OTH',
  SMA: 'SMA',
}

export type ProductTypes = ProductType[]

const productTypes: ProductTypes = [
  {
    code: ProductTypeEnum.SMA,
    image:
      'https://drpciyuuf9kdh.cloudfront.net/save/quote/product-types-web/SMA.png',
    label: 'Smartphone',
    urlCode: 'smartphone',
    arianeLabel: 'Mon smartphone',
  },
  {
    code: ProductTypeEnum.TAB,
    image:
      'https://drpciyuuf9kdh.cloudfront.net/save/quote/product-types-web/TAB.png',
    label: 'Tablette',
    urlCode: 'tablette',
    arianeLabel: 'Ma tablette',
  },
  {
    code: ProductTypeEnum.LPT,
    image:
      'https://drpciyuuf9kdh.cloudfront.net/save/quote/product-types-web/LPT.png',
    label: 'Ordinateur portable',
    urlCode: 'ordinateur',
    arianeLabel: 'Mon ordinateur',
  },
  {
    code: ProductTypeEnum.IT,
    image:
      'https://drpciyuuf9kdh.cloudfront.net/save/quote/product-types-web/IT.png',
    label: 'Ordinateur de Bureau',
    urlCode: 'ordinateur-bureau',
    arianeLabel: 'Mon ordinateur',
  },
  {
    code: ProductTypeEnum.CSL,
    image:
      'https://drpciyuuf9kdh.cloudfront.net/save/quote/product-types-web/CSL.png',
    label: 'Console',
    urlCode: 'console',
    arianeLabel: 'Ma console',
  },
  {
    code: ProductTypeEnum.WEA,
    image:
      'https://drpciyuuf9kdh.cloudfront.net/save/quote/product-types-web/WEA.png',
    label: 'Wearable',
    urlCode: 'wearable',
    arianeLabel: 'Ma wearable',
  },
  {
    code: ProductTypeEnum.OTH,
    image:
      'https://drpciyuuf9kdh.cloudfront.net/save/quote/product-types-web/SMA.png',
    label: 'Autre',
    urlCode: 'autre',
    arianeLabel: 'Autre',
  },
]
export const getProductByCode = (code: string): ProductType | undefined => {
  return productTypes.find((product) => product.code === code)
}

export const getProductByUrlCode = (code: string): ProductType | undefined => {
  return productTypes.find((product) => product.urlCode === code)
}
export default productTypes
