import { SaveQuoteBrandsQueryVariables } from '../_generated/codegen/graphqlTypes'
import { reparationsLinks } from '../data/menu.context'

export type RepairPageContext = {
  urlCode: string
} & SaveQuoteBrandsQueryVariables

export const repairBrandProductPageBuilder = (urlCode: string) => {
  if (urlCode === 'smartphone') {
    return `${reparationsLinks.all}`
  }
  return `${reparationsLinks.all}${(urlCode ?? '').toLowerCase()}/`
}
