import { useStaticQuery, graphql } from 'gatsby'
import { BannersQuery } from '../_generated/codegen/graphqlTypes'
import { Unpacked } from '../utils/Unpacked'
import notEmpty from '../utils/notEmpty'

export type IBanner = NonNullable<Unpacked<BannersQuery['strapi']['banners']>>

const useBannersQuery = (): IBanner[] => {
  const { strapi }: BannersQuery = useStaticQuery(
    graphql`
      query Banners {
        strapi {
          banners {
            type
            title
            reg_exp
            content
          }
        }
      }
    `
  )
  return strapi.banners?.filter(notEmpty) ?? []
}

export default useBannersQuery
