import React from 'react'
import styled from 'styled-components'
import NavBar from './NavBar'

const Container = styled.div`
  font-family: GothamRounded;
  top: 0px;
  transform: translate3d(0, 0, 0);
  position: fixed;
  width: 100%;
  z-index: 90;
  background-color: ${({ theme }) => theme.colors.secondary};

  & > * {
    max-width: ${({ theme }) => theme.container.maxWidth};
    margin: 0 auto;
  }

  @media print {
    display: none !important;
  }
`

const Header: React.FC = () => (
  <Container>
    <NavBar />
  </Container>
)

export default Header
