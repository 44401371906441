import { useStaticQuery, graphql } from 'gatsby'
import { SiteMetadataQuery } from '../_generated/codegen/graphqlTypes'

const useSiteMetadata = () => {
  const { site }: SiteMetadataQuery = useStaticQuery(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            title
            siteUrl
            siteUrlWithoutSlash
          }
        }
      }
    `
  )
  return site?.siteMetadata
}

export default useSiteMetadata
