import { useStaticQuery, graphql } from 'gatsby'
import { SaveMenuQuery } from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'

export type ISaveMenu = NonNullable<
  Unpacked<SaveMenuQuery['allSaveMenu']['nodes']>
>

const useSaveMenuQuery = (): ISaveMenu | undefined => {
  const { allSaveMenu }: SaveMenuQuery = useStaticQuery(
    graphql`
      query SaveMenu {
        allSaveMenu(
          sort: {
            fields: [brands___weigth, models___weigth]
            order: [DESC, DESC]
          }
        ) {
          nodes {
            brands {
              name
              weigth
            }
            models {
              name
              slugFull
              brandName
              weigth
            }
          }
        }
      }
    `
  )
  const items = allSaveMenu.nodes.filter(notEmpty) ?? []
  if (items.length > 0) {
    return items[0]
  }
  return undefined
}

export default useSaveMenuQuery
