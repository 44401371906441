import React, { createContext, useContext, useEffect, useState } from 'react'
import GlobalStyle from './GlobalStyle'
import { ThemeProvider } from 'styled-components'
import theme from '../theme/theme'
import { PageProps } from 'gatsby'
import { LayoutProps } from './Layout.context'
import { WindowLocation } from '@reach/router'
import { useIsomorphicEffect } from '../hooks/useIsomorphicEffect'

const layoutContext = createContext<(layoutProps: LayoutProps) => void>(
  () => {}
)

const LayoutContextProvider = layoutContext.Provider

export const useLayoutContext = (layoutProps: LayoutProps) => {
  const setLayoutProps = useContext(layoutContext)

  useEffect(() => {
    setLayoutProps(layoutProps)
  }, [layoutProps])
}

const locationContext = createContext<WindowLocation<unknown> | undefined>(
  undefined
)

const LocationContextProvider = locationContext.Provider

export const useLocation = () => {
  return useContext(locationContext)
}

interface Props extends Omit<PageProps, 'children'> {
  layoutProps?: LayoutProps
  children: React.ReactNode
}

const LayoutExternal: React.FC<Props> = ({ children, location }) => {
  const [layoutContextState, setLayoutContextState] = useState<
    LayoutProps | undefined
  >(undefined)
  const isomorphicEffect = useIsomorphicEffect()

  isomorphicEffect(() => {
    setLayoutContextState(undefined)
  }, [location.pathname])

  return (
    <>
      <ThemeProvider theme={theme}>
        <LocationContextProvider value={location}>
          <GlobalStyle />
          <main>
            <LayoutContextProvider value={setLayoutContextState}>
              {children}
            </LayoutContextProvider>
          </main>
        </LocationContextProvider>
      </ThemeProvider>
    </>
  )
}

export default LayoutExternal
