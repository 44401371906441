import { reparationsLinks } from '../data/menu.context'
import { RepairModelsQueryVariables } from '../_generated/codegen/graphqlTypes'

export type RepairBrandPageContext = RepairModelsQueryVariables & {
  shopsCount: number
}

export const repairBrandPageBuilder = (brandName: string) => {
  return `${reparationsLinks.all}${brandName.toLowerCase()}/`
}
