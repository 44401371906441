import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  colors: {
    primary: '#00BFB2',
    secondary: '#0F4C81',
    tertiary: '#78BB6C',
    quaternary: '#721f80',
    body: '#373F41',
    background: '#F8F9FC',
    muted: '#e5e5e5',
    danger: '#FF416C',
    placeholder: '#c4c4c4',
    error: '#cb0404',
  },
  breakpoints: {
    xs: 'px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },
  shadows: {
    xs: '0px 4px 10px 0px #00000040',
    sm: '0px 4px 15px 0px #00000080',
    md: '0px 4px 21px 0px #000000BF',
    lg: '0px 4px 26px 0px #000000E5',
    xl: '0px 4px 31px 0px #000000',
  },
  container: {
    maxWidth: '1068px',
  },
  breadcrumb: {
    mobile: {
      minHeight: '31px',
    },
    desktop: {
      minHeight: '50px',
    },
  },
  navbar: {
    mobile: {
      minHeight: '44px',
    },
    desktop: {
      minHeight: '81px',
    },
  },
  transitions: {
    fadeUp: {
      duration: 0.3,
      delay: 0.1,
    },
  },
}

export default theme
