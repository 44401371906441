const isReducedMotionPrefered = () => {
  if (typeof navigator === 'undefined') {
    return true
  }

  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
  return !mediaQuery || mediaQuery.matches
}

export default isReducedMotionPrefered
