import React from 'react'
import styled, { css } from 'styled-components'
import { Menu } from '../data/menu'
import isExternalLink from '../utils/isExternalLink'
import LinkExtended from './LinkExtended'

const MenuDesktopWrapper = styled.ul`
  display: flex;
  align-items: stretch;

  &[data-responsive='true'] {
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }
`

const animationStart = css`
  opacity: 0;
  transition: all ease 300ms;
`

const animationEnd = css`
  opacity: 1;
`

const MenuDesktopItem = styled.li`
  display: inline-flex;

  & > a + div {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    & > a + div {
      display: flex;
    }

    & > a:after {
      content: '';
      z-index: 5;
      display: block;
      border: 14px solid transparent;
      border-bottom-color: white;
      position: absolute;
      bottom: 0px;
      left: 50%;
      margin-left: -14px;
      ${animationStart}
    }

    &:hover > a:after {
      ${animationEnd}
    }

    & > a + div {
      visibility: hidden;
      ${animationStart}
    }

    &:hover > a + div {
      visibility: visible;
      ${animationEnd}
    }
  }
`

const menuDesktopLink = css`
  color: white;
  font-weight: normal;
  text-decoration: none;
  font-size: 16px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  padding: 0 10px;
  padding-bottom: 10px;

  &[data-icon='true'] {
    padding-left: 3px;
    padding-right: 3px;
  }

  &:focus,
  &:hover {
    color: white;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: 30px;
  }
`

const MenuDesktopLink = styled(LinkExtended)`
  ${menuDesktopLink}
`

const MenuDesktopExternalLink = styled.a`
  ${menuDesktopLink}
`

const Panel = styled.div`
  background: white;
  position: absolute;
  top: 100%;
  display: flex;
  box-shadow: ${({ theme }) => theme.shadows.sm};

  &[data-right='true'] {
    right: 0;
  }

  &:not([data-right='true']) {
    left: 0;
  }
`

const PanelPaddingCommon = css`
  padding-left: 32px;
  padding-bottom: 22px;
  padding-right: 32px;
  min-width: 250px;
`
const PanelPaddingTop = '28px'

const PanelListNested = styled.ul`
  display: flex;
  margin-right: auto;
  ${PanelPaddingCommon}

  & > li {
    margin-top: ${PanelPaddingTop};
  }
`

const PanelList = styled.ul`
  margin-right: auto;
  ${PanelPaddingCommon}
  padding-top: ${PanelPaddingTop};
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const DividerVertical = styled.hr`
  background: #e5e5e5;
  border: none;
  width: 2px;
  margin: 44px 22px;
`

const PanelListNestedLink = styled(LinkExtended)`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.colors.body};
  text-decoration: none;
  display: block;
  margin-bottom: 8px;

  &:hover {
    background: rgba(15, 76, 129, 0.1);
  }
`

const PanelListNestedSubLink = styled(LinkExtended)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};
  display: block;
  padding: 2px 0 1px 12px;

  &[data-decoration='true'] {
    text-decoration: underline;
  }

  &:not([data-decoration='true']) {
    text-decoration: none;
  }

  &:hover {
    background: rgba(15, 76, 129, 0.1);
  }
`

const PanelListLink = styled(LinkExtended)`
  color: ${({ theme }) => theme.colors.body};
  text-decoration: none;
  display: block;
  padding: 2px 0 1px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  &:hover {
    background: rgba(15, 76, 129, 0.1);
  }
`

const Illustration = styled.img`
  object-fit: cover;
`

const MenuDesktopIcon = styled.span`
  display: inline-flex;
  box-shadow: ${({ theme }) => theme.shadows.xs};
  border-radius: 50%;
  width: 27px;
  height: 27px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 33px;
    height: 33px;
  }
`
const LibelleSpan = styled.span``
interface MenuDesktopProps {
  menu: Menu
  responsive?: boolean
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({ menu, responsive }) => (
  <MenuDesktopWrapper data-responsive={responsive ? 'true' : undefined}>
    {menu.map((item, index) => (
      <MenuDesktopItem key={index}>
        {isExternalLink(item.url) ? (
          <MenuDesktopExternalLink href={item.url}>
            <LibelleSpan>{item.label}</LibelleSpan>
          </MenuDesktopExternalLink>
        ) : (
          <MenuDesktopLink
            to={item.url}
            data-icon={item.icon ? 'true' : undefined}
            noprefetch
          >
            {item.icon ? (
              <MenuDesktopIcon>
                <img src={item.icon} alt={item.label} />
              </MenuDesktopIcon>
            ) : (
              <LibelleSpan>{item.label}</LibelleSpan>
            )}
          </MenuDesktopLink>
        )}

        {item.items && (
          <Panel data-right={item.position !== 'left' ? 'true' : undefined}>
            {item.nested ? (
              <PanelListNested>
                {item.items.map((subItem, subIndex) => (
                  <React.Fragment key={subIndex}>
                    <li>
                      <PanelListNestedLink to={subItem.url} noprefetch>
                        {subItem.label}
                      </PanelListNestedLink>
                      <ul>
                        {subItem.items?.map((subSubItem, subSubIndex) => (
                          <li key={subSubIndex}>
                            <PanelListNestedSubLink
                              to={subSubItem.url}
                              noprefetch
                            >
                              {subSubItem.label}
                            </PanelListNestedSubLink>
                          </li>
                        ))}
                        {subItem.other && (
                          <li>
                            <PanelListNestedSubLink
                              to={subItem.other.url}
                              data-decoration="true"
                              noprefetch
                            >
                              {subItem.other.label}
                            </PanelListNestedSubLink>
                          </li>
                        )}
                        {subItem.url2 && (
                          <PanelListNestedLink
                            to={subItem.url2}
                            noprefetch
                            style={{
                              textDecoration: 'underline',
                              marginBottom: '0px',
                              marginTop: '15px',
                            }}
                          >
                            {subItem.label2}
                          </PanelListNestedLink>
                        )}
                      </ul>
                    </li>
                    {item && item.items && subIndex < item.items.length - 1 && (
                      <DividerVertical />
                    )}
                  </React.Fragment>
                ))}
              </PanelListNested>
            ) : (
              <>
                <PanelList>
                  {item.items.map((subItem, subIndex) => (
                    <li key={subIndex}>
                      <PanelListLink to={subItem.url} noprefetch>
                        {subItem.label}
                      </PanelListLink>
                    </li>
                  ))}
                </PanelList>
                {item.image && (
                  <Illustration
                    src={item.image}
                    alt="illustration"
                    width={306}
                    height={244}
                    loading="lazy"
                  />
                )}
              </>
            )}
          </Panel>
        )}
      </MenuDesktopItem>
    ))}
  </MenuDesktopWrapper>
)

export default MenuDesktop
