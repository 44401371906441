import React, { Suspense, useRef } from 'react'
import { useClickAway, useToggle } from 'react-use'
import styled from 'styled-components'
import isServerSideRendering from '../utils/isServerSideRendering'

const SearchFormLazy = React.lazy(() => import('./SearchForm'))

if (!isServerSideRendering()) {
  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => import('./SearchForm'), 3 * 1000)
  })
}

const SearchDesktopIconWrapper = styled.span`
  display: none;
  align-items: center;
  justify-content: center;
  margin-left: 36px;
  margin-right: 10px;
  padding-top: 11px;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: inline-flex;
  }
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  z-index: 1;
`

const FormContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

const SearchFormWrapper = styled(SearchFormLazy)`
  z-index: 2;
  max-width: 213px;
`

interface Props {
  children: React.ReactNode
}

const SearchDesktop: React.FC<Props> = ({ children }) => {
  const [isOpen, toggleSearch] = useToggle(false)
  const ref = useRef(null)
  useClickAway(ref, () => {
    toggleSearch()
  })

  if (isOpen) {
    return (
      <>
        <FormContainer>
          <Suspense fallback={<></>}>
            <SearchFormWrapper
              autoFocus
              ref={ref}
              variant="desktop"
              onEscape={toggleSearch}
            />
          </Suspense>
        </FormContainer>
        <Backdrop />
      </>
    )
  }

  return (
    <>
      {children}
      <SearchDesktopIconWrapper>
        <img
          src={`${process.env.GATSBY_SITE_URL}images/media/search.svg`}
          width={27}
          height={27}
          alt="search"
          onClick={toggleSearch}
        />
      </SearchDesktopIconWrapper>
    </>
  )
}

export default SearchDesktop
