import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, MotionProps, useAnimation } from 'framer-motion'
import theme from '../theme/theme'
import isAnimationEnabled from '../utils/isAnimationEnabled'
import { fadeUpWhenVisibleClassName } from './FadeUpWhenVisible.nostyle'

interface FadeUpWhenVisibleProps {
  delay?: number
  className?: string
  component?: 'li'
  children: React.ReactNode
}

const FadeUpWhenVisible: React.FC<FadeUpWhenVisibleProps & MotionProps> = ({
  delay,
  className,
  children,
  component,
  ...rest
}) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  const enabled = isAnimationEnabled()

  useEffect(() => {
    if (!enabled) {
      return
    }

    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const props = {
    className: className + ` ${fadeUpWhenVisibleClassName}`,
    animate: controls,
    initial: enabled ? 'hidden' : 'visible',
    transition: {
      duration: theme.transitions.fadeUp.duration,
      type: 'spring',
      delay,
    },
    variants: {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: 50 },
    },
  }

  if (component === 'li') {
    return (
      <motion.li ref={ref} {...props} {...rest}>
        {children}
      </motion.li>
    )
  }

  return (
    <motion.div ref={ref} {...props} {...rest}>
      {children}
    </motion.div>
  )
}

export default FadeUpWhenVisible
