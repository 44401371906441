import { RepairsQueryVariables } from '../_generated/codegen/graphqlTypes'
import { repairBrandPageBuilder } from './RepairBrandPage.context'
import { repairOtherBrandPageBuilder } from './RepairOtherBrandPage.context'

export type RepairModelPageContext = {
  brandName: string
  modeleName: string
  slug: string
  name: string
  brandNameRegex: string
  modeleNameRegex: string
  productType?: string
} & RepairsQueryVariables

export const repairModelPageBuilder = (
  brandName: string,
  slug: string,
  productType?: string
) => {
  if (productType && productType != '') {
    return `${repairOtherBrandPageBuilder(productType, brandName)}${slug}/`
  }
  return `${repairBrandPageBuilder(brandName)}${slug}/`
}
