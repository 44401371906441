import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'
import clamp from 'lodash/clamp'
import { AnimatePresence, motion } from 'framer-motion'
import AspectRatio from './AspectRatio'
import styled from 'styled-components'

const AspectRatioWrapper = styled(AspectRatio)`
  & > span > svg {
    width: 100%;
    height: 100%;
  }
`

const Badge: React.FC<React.SVGProps<SVGGElement> & { step: number }> = ({
  step,
  ...rest
}) => (
  <g {...rest}>
    <AnimatePresence exitBeforeEnter initial={false}>
      {step === 0 && (
        <motion.g
          key={0}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
        >
          <ellipse cx="33.5" cy="33" rx="33.5" ry="33" fill="#C4C4C4" />
          <path
            d="M25.1349 42.1334C25.1349 40.3349 26.2399 39.3703 27.7426 38.888L42.7592 33.8441L22.4277 26.8108C17.7427 25.595 20.494 18.7024 25.1349 20.8526L48.6376 29.9557C52.8476 31.282 52.9139 36.5268 48.6376 37.8431L30.3393 45.0371C28.1404 46.072 25.1349 44.6654 25.1349 42.1334Z"
            fill="white"
          />
        </motion.g>
      )}

      {step === 1 && (
        <motion.g
          key={1}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
        >
          <path
            d="M65 33C65 50.0926 50.9254 64 33.5 64C16.0746 64 2 50.0926 2 33C2 15.9074 16.0746 2 33.5 2C50.9254 2 65 15.9074 65 33Z"
            fill="white"
            stroke="#00BFB2"
            strokeWidth="4"
          />
          <path
            d="M24.2465 22.1349C26.045 22.1349 27.0096 23.2399 27.4919 24.7426L32.5358 39.7592L39.5691 19.4277C40.7849 14.7427 47.6775 17.494 45.5273 22.1349L36.4242 45.6376C35.0979 49.8476 29.8531 49.9139 28.5368 45.6376L21.3427 27.3393C20.3078 25.1404 21.7145 22.1349 24.2465 22.1349Z"
            fill="#00BFB2"
          />
        </motion.g>
      )}

      {step >= 2 && (
        <motion.g
          key={2}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
        >
          <circle cx="33" cy="33" r="33" fill="#00BFB2" />
          <path
            d="M49.9994 46.7529C50.0876 52.4157 41.1029 52.4157 41.1911 46.7529C41.1029 41.1026 50.0876 41.1026 49.9994 46.7529ZM20.0716 20.7964C22.3272 20.7964 23.5369 22.0437 24.1418 23.74L30.4675 40.691L39.2884 17.7405C40.8131 12.4519 49.4575 15.5577 46.7609 20.7964L35.3442 47.3267C33.6808 52.0789 27.103 52.1538 25.4523 47.3267L16.4298 26.6712C15.1319 24.1891 16.8961 20.7964 20.0716 20.7964Z"
            fill="white"
          />
        </motion.g>
      )}
    </AnimatePresence>
  </g>
)

const Bar: React.FC<React.SVGProps<SVGGElement> & { value: number }> = ({
  value,
  ...rest
}) => {
  const uid = useMemo(() => v4(), [])
  const id = useCallback((id: string) => `${id}-${uid}`, [uid])
  const valueWidth = useMemo(() => clamp(101 * value, 0, 101), [value])

  return (
    <g {...rest}>
      <rect
        id={id('progress')}
        x="0"
        y="29"
        width="101"
        height="8"
        rx="4"
        shapeRendering="crispEdges"
        stroke="white"
      />

      <g>
        <clipPath id={id('background')}>
          <rect
            x="0"
            y="29"
            width="101"
            height="8"
            strokeWidth={'0px'}
            stroke="#000000"
          />
        </clipPath>
        <clipPath id={id('value')}>
          <motion.rect
            y="29"
            height="8"
            animate={{ x: 0, width: valueWidth }}
            initial={false}
            transition={{ type: 'ease' }}
            strokeWidth={'0px'}
            stroke="#000000"
          />
        </clipPath>
      </g>

      <g>
        <use
          xlinkHref={`#${id('progress')}`}
          clipPath={`url(#${id('background')})`}
          fill="#C4C4C4"
          strokeWidth={'0px'}
          stroke="#000000"
        />
        <use
          xlinkHref={`#${id('progress')}`}
          clipPath={`url(#${id('value')})`}
          fill="#00BFB2"
          strokeWidth={'0px'}
          stroke="#000000"
        />
      </g>
    </g>
  )
}

const padding = {
  horizontal: 10,
  vertical: 10,
}

export interface RepairProgressProps {
  step: number
}

const RepairProgress: React.FC<RepairProgressProps> = ({ step }) => {
  if (step === undefined) {
    return <></>
  }

  const badge1 = useMemo(() => {
    switch (step) {
      case 0:
        return 1
      case 1:
        return 1
      case 2:
        return 2
      case 3:
        return 2
      case 4:
        return 2
      case 5:
        return 2
      default:
        return 2
    }
  }, [step])

  const badge2 = useMemo(() => {
    switch (step) {
      case 0:
        return 0
      case 1:
        return 0
      case 2:
        return 0
      case 3:
        return 1
      case 4:
        return 2
      case 5:
        return 2
      default:
        return 2
    }
  }, [step])

  const badge3 = useMemo(() => {
    switch (step) {
      case 0:
        return 0
      case 1:
        return 0
      case 2:
        return 0
      case 3:
        return 0
      case 4:
        return 1
      case 5:
        return 2
      default:
        return 2
    }
  }, [step])

  const bar1 = useMemo(() => {
    switch (step) {
      case 0:
        return 0
      case 1:
        return 1 / 3
      case 2:
        return 2 / 3
      case 3:
        return 1
      case 4:
        return 1
      case 5:
        return 1
      default:
        return 1
    }
  }, [step])

  const bar2 = useMemo(() => {
    switch (step) {
      case 0:
        return 0
      case 1:
        return 0
      case 2:
        return 0
      case 3:
        return 0
      case 4:
        return 1
      case 5:
        return 1
      default:
        return 1
    }
  }, [step])

  const size = useMemo(
    () => ({
      width: 430 + 2 * padding.horizontal,
      height: 66 + 2 * padding.vertical,
    }),
    []
  )
  return (
    <AspectRatioWrapper mobile={size} desktop={size} className="RepairProgress">
      <span>
        <svg
          width={size.width}
          height={size.height}
          viewBox={`0 0 ${size.width} ${size.height}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <Badge
            transform={`translate(${padding.horizontal} ${padding.vertical})`}
            step={badge1}
          />
          <Badge
            transform={`translate(${181 + padding.horizontal} ${
              padding.vertical
            })`}
            step={badge2}
          />
          <Badge
            transform={`translate(${363 + padding.horizontal} ${
              padding.vertical
            })`}
            step={badge3}
          />

          <Bar
            transform={`translate(${74 + padding.horizontal} ${
              padding.vertical
            })`}
            value={bar1}
          />
          <Bar
            transform={`translate(${255 + padding.horizontal} ${
              padding.vertical
            })`}
            value={bar2}
          />
        </svg>
      </span>
    </AspectRatioWrapper>
  )
}

export default RepairProgress
