import React, { useMemo } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'
import socialLinks from '../data/socialLinks'
import classNames from 'classnames'

const list = css`
  display: flex;
  flex-direction: row;
  gap: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 17px;
  }
`

const FixScopedListCSS = createGlobalStyle`
  .SocialLinks {
    ${list}
  }
`

export const socialLinksVerySmall = css`
  gap: 7px;

  span {
    width: 22px;
    height: 22px;
  }
`

const List = styled.ul`
  ${list}
`

const Icon = styled.span`
  display: inline-flex;
  width: 38px;
  height: 38px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 60px;
    height: 60px;
  }
`

interface ImgProps {
  shadow?: boolean
}

const Img = styled.img<ImgProps>`
  ${({ shadow }) =>
    shadow &&
    css`
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    `}
`

export interface Share {
  title: string
  url: string
}

const buildShareUrl = (
  type: 'instagram' | 'facebook' | 'linkedin' | 'twitter',
  share: Share
) => {
  const encodedUri = encodeURI(share.url)
  const encodedTitle = encodeURIComponent(share.title)

  switch (type) {
    case 'facebook':
      return `https://www.facebook.com/sharer.php?u=${encodedUri}&p[title]=${encodedTitle}`
    case 'linkedin':
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUri}&title=${encodedTitle}`
    case 'twitter':
      return `https://twitter.com/share?text=${encodedTitle}&url=${encodedUri}`
  }
}

interface SocialLinks {
  className?: string
  shadow?: boolean
  share?: Share
}

const SocialLinks: React.FC<SocialLinks> = ({ className, shadow, share }) => {
  const items = useMemo(
    () =>
      socialLinks.map((item) => {
        const url = share ? buildShareUrl(item.type, share) : item.url

        return {
          ...item,
          url,
        }
      }),
    [share]
  )

  return (
    <>
      <FixScopedListCSS />
      <List className={classNames('SocialLinks', className)}>
        {items.map((link, index) => (
          <li key={index}>
            <a href={link.url} target="_blank" rel="noreferrer">
              <Icon>
                <Img shadow={shadow} src={link.icon} alt={link.label} />
              </Icon>
            </a>
          </li>
        ))}
      </List>
    </>
  )
}

export default SocialLinks
