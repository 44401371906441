import FacebookIcon from '../images/icon-fab.png'
import TwitterIcon from '../images/icon-twitter.png'
import LinkedInIcon from '../images/icon-linkedin.png'
import InstagramIcon from '../images/Instagram.png'

type SocialLink = {
  type: 'instagram' | 'facebook' | 'twitter' | 'linkedin'
  label: string
  icon: string
  url: string
}

type SocialLinks = SocialLink[]

const socialLinks: SocialLinks = [
  {
    type: 'instagram',
    label: 'Instagram',
    icon: InstagramIcon,
    url: 'https://www.instagram.com/save.co/',
  },
  {
    type: 'facebook',
    label: 'Facebook',
    icon: FacebookIcon,
    url: 'https://www.facebook.com/save.co',
  },
  {
    type: 'twitter',
    label: 'Twitter',
    icon: TwitterIcon,
    url: 'https://twitter.com/save_reparation',
  },
  {
    type: 'linkedin',
    label: 'LinkedIn',
    icon: LinkedInIcon,
    url: 'https://www.linkedin.com/company/save-groupe/',
  },
]

export default socialLinks
