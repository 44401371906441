import React, { useMemo } from 'react'
import useBannersQuery from '../hooks/useBannersQuery'
import { WindowLocation } from '@reach/router'
import Banner from './Banner'

interface BannersProps {
  location: WindowLocation<unknown>
}

const Banners: React.FC<BannersProps> = ({ location }) => {
  const banners = useBannersQuery()
  const bannersFiltered = useMemo(
    () => banners.filter((x) => location.pathname.match(x.reg_exp)),
    [banners, location]
  )

  return (
    <>
      {bannersFiltered.map((banner, index) => (
        <Banner
          key={index}
          type={banner.type}
          title={banner.title}
          description={
            <div dangerouslySetInnerHTML={{ __html: banner.content }} />
          }
        />
      ))}
    </>
  )
}

export default Banners
